import { $findMatchingParent } from '@lexical/utils';
import { OrderedSet } from 'immutable';
import { $getSelection, $isElementNode, $isNodeSelection, $isRangeSelection, ElementFormatType, ElementNode } from 'lexical';
import * as Constants from 'const';
import { BaseProps } from './types';

const elementNodeFormatMapToBlockType: {
  [key in ElementFormatType]?: Constants.TextHorizontalAlignmentType
} = {
  left: Constants.TextHorizontalAlignmentType.LEFT,
  center: Constants.TextHorizontalAlignmentType.CENTER,
  right: Constants.TextHorizontalAlignmentType.RIGHT,
  justify: Constants.TextHorizontalAlignmentType.JUSTIFY,
};

function getBlockTypeFromSelection(
  selection: ReturnType<typeof $getSelection>,
): BaseProps['blockType'] {
  if (!$isRangeSelection(selection) && !$isNodeSelection(selection)) {
    return Constants.TextHorizontalAlignmentType.LEFT;
  }

  for (const node of selection.getNodes()) {
    const element = $findMatchingParent(
      node,
      (parentNode): parentNode is ElementNode => $isElementNode(parentNode) && !parentNode.isInline(),
    );
    if (element) {
      const type: ElementFormatType = (element as ElementNode).getFormatType() ;

      return (type && elementNodeFormatMapToBlockType[type]) || Constants.TextHorizontalAlignmentType.LEFT;
    }
  }

  return Constants.TextHorizontalAlignmentType.LEFT;
}

function getInlineStyleFromSelection(
  selection: ReturnType<typeof $getSelection>,
): BaseProps['inlineStyle'] {
  const inlineStyle: Constants.InlineStyle[] = [];
  if ($isRangeSelection(selection)) {
    if (selection.hasFormat('bold')) {
      inlineStyle.push(Constants.InlineStyle.BOLD);
    }
    if (selection.hasFormat('italic')) {
      inlineStyle.push(Constants.InlineStyle.ITALIC);
    }
    if (selection.hasFormat('underline')) {
      inlineStyle.push(Constants.InlineStyle.UNDERLINE);
    }
  }

  return OrderedSet(inlineStyle);
}

function getScriptStyleFromSelection(
  selection: ReturnType<typeof $getSelection>,
): BaseProps['scriptStyle'] {
  if (!$isRangeSelection(selection)) {
    return undefined;
  }

  if (selection.hasFormat('superscript')) {
    return Constants.ScriptType.SUPERSCRIPT;
  }

  if (selection.hasFormat('subscript')) {
    return Constants.ScriptType.SUBSCRIPT;
  }

  return undefined;
}

export function $getBaseProps(): BaseProps {
  const selection = $getSelection();

  return {
    blockLineHeight: Constants.TextLineHeightValue.ONE, // IN-PROGRESS: should be read from selection
    blockType: getBlockTypeFromSelection(selection),
    inlineStyle: getInlineStyleFromSelection(selection),
    scriptStyle: getScriptStyleFromSelection(selection),
    textNoWrap: false, // IN-PROGRESS: should be read from selection
  };
}

export function $hasSuperscript(): boolean {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return false;
  }

  return selection.hasFormat('superscript');
}

export function $hasSubrscript(): boolean {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return false;
  }

  return selection.hasFormat('subscript');
}
