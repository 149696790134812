import { $getSelectionStyleValueForProperty } from '@lexical/selection';
import { $getSelection, $isRangeSelection } from 'lexical';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import { fromPx } from 'utils/toPx';
import { FontProps } from './types';

function getFontColorFromSelection(
  selection: ReturnType<typeof $getSelection>,
  colors: Models.BrandColorsList,
): Models.BrandColorMap | undefined {
  if (!$isRangeSelection(selection)) {
    return undefined;
  }
  const colorHex = $getSelectionStyleValueForProperty(selection, 'color');

  // IN-PROGRESS: colorName and colorTint should be stored
  return editorUtils.getBrandColor(
    colors,
    colorHex,
    0,
  );
}

function getFontFamilyFromSelection(
  selection: ReturnType<typeof $getSelection>,
): Models.FontFamily | undefined {
  if (!$isRangeSelection(selection)) {
    return undefined;
  }

  const fontFamily = $getSelectionStyleValueForProperty(selection, 'font-family');
  if (!fontFamily) {
    return undefined;
  }

  // IN-PROGRESS: other props should also be added
  return { fontFamily };
}

function getFontSizeFromSelection(
  selection: ReturnType<typeof $getSelection>,
): number | undefined {
  if (!$isRangeSelection(selection)) {
    return undefined;
  }
  const value = $getSelectionStyleValueForProperty(selection, 'font-size');

  // IN-PROGRESS: default fontSize value ??
  return value ? fromPx(value) : undefined;
}

export function $getFontProps(
  colors: Models.BrandColorsList,
): FontProps {
  const selection = $getSelection();

  return {
    brandColor: getFontColorFromSelection(selection, colors),
    family: getFontFamilyFromSelection(selection),
    size: getFontSizeFromSelection(selection),
  };
}
