import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import * as Models from 'models';
import { registerCommands } from './commands';
import { FontProps } from './types';
import { $getFontProps } from './utils';

export { FONT_COMMAND } from './commands';
export type { FontProps } from './types';

type Props = {
  brandStyle: Models.BrandStyleMap | undefined;
  colors: Models.BrandColorsList;
  onChange: (props: FontProps) => void;
};

export function FontPlugin(props: Props): null {
  const { onChange, brandStyle, colors } = props;
  const [editor] = useLexicalComposerContext();

  useEffect(
    () => registerCommands(editor, brandStyle, colors),
    [editor, brandStyle, colors],
  );

  useEffect(() => {
    const readAndNotifyChange = (): void => onChange($getFontProps(colors));
    editor.getEditorState().read(readAndNotifyChange);

    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(readAndNotifyChange);
    });
  }, [editor, onChange, colors]);

  return null;
}
