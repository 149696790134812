import { $patchStyleText } from '@lexical/selection';
import { mergeRegister } from '@lexical/utils';
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_EDITOR, LexicalEditor, createCommand } from 'lexical';
import { getBrandColorProperties } from 'components/ArtboardAssets/Text/utils/brand';
import * as Models from 'models';
import { toPx } from 'utils/toPx';

export const FONT_COMMAND = {
  BRAND_COLOR: createCommand<Models.BrandColorMap | undefined>(),
  FAMILY: createCommand<{
    font: Models.BrandFontMap;
    characterStyle: Models.CharacterStyleMap;
  }>(),
  SIZE: createCommand<number>(),
} as const;

export function registerCommands(
  editor: LexicalEditor,
  brandStyle: Models.BrandStyleMap | undefined,
  colors: Models.BrandColorsList,
): () => void {
  return mergeRegister(

    editor.registerCommand(
      FONT_COMMAND.BRAND_COLOR,
      (brandColor) => {
        const { colorHEX } = getBrandColorProperties(
          brandColor,
          brandStyle,
          colors,
        );
        editor.update(() => {
          const selection = $getSelection();
          if (selection && $isRangeSelection(selection)) {
            // IN-PROGRESS: colorName and colorTint should be stored
            $patchStyleText(selection, { color: colorHEX });
          }
        });

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      FONT_COMMAND.FAMILY,
      (value) => {
        editor.update(() => {
          const selection = $getSelection();
          if (selection && $isRangeSelection(selection)) {
            // IN-PROGRESS: other props should be taken into account
            $patchStyleText(selection, { ['font-family']: value.font.get('name') });
          }
        });

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      FONT_COMMAND.SIZE,
      (size) => {
        editor.update(() => {
          const selection = $getSelection();
          if (selection && $isRangeSelection(selection)) {
            $patchStyleText(selection, { ['font-size']: toPx(size) });
          }
        });

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),
  );
}
